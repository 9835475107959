import api from "./core/api";

const BASE_PATH = '/github';

async function authenticate() {
  return await api.get(BASE_PATH + '/authenticate');
}

async function authorize(code, nonce) {
  return await api.post(BASE_PATH + '/authorize', {
    code,
    nonce
  });
}

async function authenticateApp() {
  return await api.get(BASE_PATH + '/app/authenticate');
}

async function authorizeApp(code, nonce) {
  return await api.post(BASE_PATH + '/app/authorize', {
    code,
    nonce
  });
}

async function installApp(code, installationId) {
  return await api.post(BASE_PATH + '/app/install', {
    code,
    installationId
  });
}

const github = {
  authenticate: authenticate,
  authorize: authorize,
  authenticateApp: authenticateApp,
  authorizeApp: authorizeApp,
  installApp: installApp
};

export default github;
