import React, {useEffect} from "react";
import github from "../../../backend/github";
import Loader from "../../../components/Core/Loader";
import PrivateRoute from "../../../router/PrivateRoute";

const AuthorizePage = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const setupAction = urlParams.get("setup_action");

    const code = urlParams.get("code");

    if (setupAction === 'install') {
      const installationId = urlParams.get("installation_id");

      github.installApp(code, installationId)
        .then(({data}) => {
          const {url} = data;

          if (typeof url === "undefined") {
            alert("Error! Please try again.");
          } else {
            window.location.href = data.url;
          }
        });
    } else {
      const nonce = urlParams.get("state");

      github.authorizeApp(code, nonce)
        .then(({data}) => {
          const {url} = data;

          if (typeof url === "undefined") {
            alert("Error! Please try again.");
          } else {
            window.location.href = data.url;
          }
        });
    }
  }, []);

  return (
    <Loader/>
  );
};

function authorize(props) {
  return <PrivateRoute component={AuthorizePage} {...props}/>
}

export default authorize;

